@media (min-width: 768px){.hidden-from-sm {
  display: none;
}

.hidden.display-to-sm {
  display: inherit !important;
}

.col-sm-2 {
  --grid-col: 2;
}

.col-sm-3 {
  --grid-col: 3;
}

.col-sm-4 {
  --grid-col: 4;
}

.col-sm-5 {
  --grid-col: 5;
}

.col-sm-6 {
  --grid-col: 6;
}

.col-sm-7 {
  --grid-col: 7;
}

.col-sm-8 {
  --grid-col: 8;
}

.col-sm-9 {
  --grid-col: 9;
}

.col-sm-12 {
  --grid-col: 12;
}}